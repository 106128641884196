import JumboDemoCard from '@jumbo/components/JumboDemoCard'
import Div from '@jumbo/shared/Div'
import { Button, Grid, ListItem, ListItemText, Modal, TextField } from '@mui/material'
import React, { useState } from 'react'
import { List, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import SweetAlert from '../components/mui/Alerts/SweetAlert';
import { postRequest } from 'backendServices/ApiCalls';

const Documentsdetail = ({ rowDataDetail, imageUrl, setUserDetailOpen, updatedData }) => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const [rowDataId, setRowDataID] = useState();
  const [rowDataName, setRowDataName] = useState();
  const [keyName, setKeyName] = useState();
  const [image, setImage] = useState(null);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = (id, value, keyName) => {
    setRowDataID(id);
    setRowDataName(value);
    setKeyName(keyName);
    setOpenUpdate(true);
  };


  const handleUpload = (e, field) => {

    if (e.target.files && e.target.files.length > 0) {
        const selectedFile = e.target.files[0];
        if (selectedFile.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result; // Extract base64 encoded string
                if (base64Data) {
                     setImage(base64Data)
                    console.log("Image loaded successfully!", base64Data);
                } else {
                    console.log("Error loading image.");
                }
            };
            reader.readAsDataURL(selectedFile); // Read the selected file as data URL
        } else {
            setalertData({
                show: true,
                message: 'Invalid file type. Please select an image file.',
                variant: 'error',
            });
        }
    }
};


const handleSubmit = () => {
  let param = {
    id: rowDataId, 
    columnName: keyName,
}
const newData = { ...param, image };
console.log('newData', newData)
  postRequest(
    "/updatedocument",
    newData,
    (response) => {
      if (response?.data?.status === "success") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: response?.data?.status,
        });
        setOpenUpdate(false);
        setUserDetailOpen(false)
        updatedData();
      } else {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: response?.data?.status,
        });
      }
    },
    (error) => {
      console.log(error?.response?.data);
    }
  );
};

  return (
    <Grid container fullWidth p={2} alignItems="center" justifyContent="center">
      <Grid item sm={6} xs={12}>
        <JumboDemoCard
          title={"Manage User Documents"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <Grid container spacing={2} style={{ overflowY: "scroll", maxHeight: "60vh" }}>
            <Grid item sm={12}>
              <ClearIcon onClick={() => setUserDetailOpen(false)} style={{ cursor: "pointer", color: "white" }} sx={{ float: "right" }} />
            </Grid>
            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Pilot License Card</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.pilotlicensecard && (
                  <a href={`${imageUrl}${rowDataDetail.pilotlicensecard}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.pilotlicensecard}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}

              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.pilotlicensecard,
                  "pilotlicensecard"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Insurance Policy</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.insurancepolicy && (
                  <a href={`${imageUrl}${rowDataDetail?.insurancepolicy}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail?.insurancepolicy}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained"
               onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.insurancepolicy,
                  "insurancepolicy"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Insurance Certificate</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.insurancecertificate && (
                  <a href={`${imageUrl}${rowDataDetail.insurancecertificate}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.insurancecertificate}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.insurancecertificate,
                  "insurancecertificate"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>My Picture</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.mypicture && (
                  <a href={`${imageUrl}${rowDataDetail.mypicture}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.mypicture}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.mypicture
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>My Security Clearance</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.mysecurityclearance && (
                  <a href={`${imageUrl}${rowDataDetail.mysecurityclearance}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.mysecurityclearance}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
               onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.mysecurityclearance,
                  "mysecurityclearance"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>My Passport Copy</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.mypassportcopy && (
                  <a href={`${imageUrl}${rowDataDetail.mypassportcopy}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.mypassportcopy}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained"
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.mypassportcopy,
                  "mypassportcopy"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>My EID Copy</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.myeidcopy && (
                  <a href={`${imageUrl}${rowDataDetail.myeidcopy}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.myeidcopy}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.myeidcopy,
                  "myeidcopy"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Liability Waiver</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.liabilitywaiver && (
                  <a href={`${imageUrl}${rowDataDetail.liabilitywaiver}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.liabilitywaiver}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.liabilitywaiver,
                  "liabilitywaiver"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Medical Fitness Declaration</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.medicalfitnessdeclaration && (
                  <a href={`${imageUrl}${rowDataDetail.medicalfitnessdeclaration}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.medicalfitnessdeclaration}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
              onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.medicalfitnessdeclaration,
                  "medicalfitnessdeclaration"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ m: "auto" }}>
              <label>Rules and Regulations Agreement</label>
              <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {rowDataDetail?.rulesandregulationsagreement && (
                  <a href={`${imageUrl}${rowDataDetail.rulesandregulationsagreement}`} target="_blank" rel="noopener noreferrer">
                    <img src={`${imageUrl}${rowDataDetail.rulesandregulationsagreement}`} style={{ width: "120px", height: "120px" }} />
                  </a>
                )}
              </Div>
              <Grid item sm={12} sx={{ display:"flex", justifyContent:"center", textAlign:"center"}}>
              <Button variant="contained" 
               onClick={() => {
                handleOpen(
                  rowDataDetail?.id, 
                  rowDataDetail?.rulesandregulationsagreement,
                  "rulesandregulationsagreement"
                  )
                }}
              >Update</Button>
              </Grid>
            </Grid>

          </Grid>

          <Modal
            open={openUpdate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update User Document
              </Typography>
              <Grid item sm={12}>
                <ClearIcon
                  onClick={() => setOpenUpdate(false)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    position: "absolute",
                    top: 3,
                    right: 3,
                    zIndex: 1000,
                  }}
                  sx={{ float: "right" }}
                />
              </Grid>
              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                      <Grid item sm={12}>
                        <Div sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img src={`${imageUrl}${rowDataName}`} style={{ width: "120px", height: "120px" }} />
                        </Div>
                      </Grid>
                      <Grid item sm={12}>
                        <ListItem sx={{ px: 0, pb: 0 }}>
                          <ListItemText
                            primary={
                              <TextField
                                onChange={handleUpload}
                                name='file'
                                type='file'
                                margin="normal"
                                InputProps={{
                                  endAdornment:
                                    <CameraAltIcon fontSize={"small"} color={"success"} />
                                }}
                              />
                            }
                          />
                        </ListItem>
                      </Grid>

                      <Grid item sm={12} sx={{ mt: 1 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          onClick={handleSubmit}
                        >
                          Update
                        </LoadingButton>
                      </Grid>
              </List>
            </Div>
          </Modal>


        </JumboDemoCard>
      </Grid>
    </Grid>
  )
}

export default Documentsdetail
