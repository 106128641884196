import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, List } from "@mui/material";
import { useState } from 'react';
import { registerApi } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';


const validationSchema = yup.object().shape({
    username: yup
        .string()
        .required('User Name is required'),
    firstname: yup
        .string()
        .required('First Name is required'),
    lastname: yup
        .string()
        .required('Last Name is required'),
    email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
});


const Adduser = ({ setOpen, handleLatestData }) => {

    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    const handleSubmit = (data, setSubmitting, resetForm) => {
        let param = {
            username: data.username,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email
        }
        registerApi(param, (response) => {
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error"
                })
                setSubmitting(false)
            }
            else if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                })
                setSubmitting(false);
                resetForm();
                setOpen(false)
                handleLatestData();
            }
            else {
                setalertData({
                    show: true,
                    message: 'Something went wrong please try again later',
                    variant: "error"
                })
                setSubmitting(false);
            }
        }, (error) => {
            console.log(error?.response?.data);
        });
    }
    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={12} xs={12} sx={{ width: "100%" }}>
                <JumboCardQuick title={"Add User"} noWrapper>
                    <ClearIcon onClick={() => setOpen(false)} style={{cursor: "pointer", color: "white", position: "absolute", top: 0, right: 0,  zIndex: 1000}} sx={{ float: "right" }} />
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize
                            initialValues={{
                                username: '',
                                firstname: '',
                                lastname: '',
                                email: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                console.log("formikData", data)
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form style={{ textAlign: 'left', width: "400px" }} noValidate autoComplete='off'>

                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="username"
                                            label="User Name"
                                            type="text"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="firstname"
                                            label="First Name"
                                            type="text"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="lastname"
                                            label="Last Name"
                                            type="text"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="email"
                                            label="Email"
                                            type="text"
                                        />
                                    </Div>


                                    <Div sx={{ mt: 1, pl: 2, pr: 2, }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Adduser;
