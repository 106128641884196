import React from 'react';
import { Card, CardContent, Badge, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Avatar from "@mui/material/Avatar";
import { Box, CircularProgress, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Popover, TextField } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useState } from 'react';
import { updateProfilePicture } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import ContentHeader from 'app/layouts/shared/headers/ContentHeader';
import ClearIcon from '@mui/icons-material/Clear';

const Userdetail = ({rowDataDetail, pictureLink, setUserDetailOpen}) => {
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData
console.log("rowDataDetail", rowDataDetail)


  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState(null);
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        setImage(selectedFile);
      } else {
        setalertData({
          show: true,
          message: 'Invalid file type. Please select an image file.',
          variant: "error"
        })
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    setIsButtonDisabled(true);
    if (!image) {
      setalertData({
        show: true,
        message: 'Invalid file type. Please select an image file.',
        variant: "error"
      })
      setBtnIsLoading(false);
      setIsButtonDisabled(false);
      return;
    }



    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result; // Extract base64 encoded string
      const formData = new FormData();
      formData.append('image', base64Data);

      updateProfilePicture(formData, (response) => {
        console.log(response);
        setBtnIsLoading(false);
        setIsButtonDisabled(false);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error"
          })
        }
        else if (response?.data?.status === "success") {
          setAnchorEl(null);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success"
          })
          setloginUserData((prevState) => ({
            ...prevState,
            profilepictureurl: response?.data?.pictureurl,
          }));
        }
        else {
          setalertData({
            show: true,
            message: 'Something went wrong please try again later',
            variant: "error"
          })

        }
      }, (error) => {
        setBtnIsLoading(false);
        setIsButtonDisabled(false);
        console.log(error?.response?.data);
        setalertData({
          show: true,
          message: 'Something went wrong please try again',
          variant: "error"
        })
      });
    };

    reader.readAsDataURL(image);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;
  return (
    <Card sx={{ maxWidth: "100%", maxHeight: "600px", overflowY: "scroll" }}>
            {/* <ClearIcon onClick={() => setUserDetailOpen(false)} style={{cursor: "pointer", color: "white", position: "fixed", marginLeft:"420px",  zIndex: 1000}} sx={{ float: "right" }} /> */}

      <CardContent>
      
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={9}>
          
            <Div style={{ marginBottom: 4 }}>
              <Typography variant="h2" color="textSecondary" sx={{ fontSize: 15 }}>Sky Adventure</Typography>
              
            </Div>
            <Div style={{ color: 'textSecondary' }}>

              <Typography variant="body1" sx={{ marginBottom: 1 }}>User Name:- {rowDataDetail?.username}</Typography>
              <Typography variant="body1" sx={{ marginBottom: 1 }}><i className="uil uil-envelope-alt me-1"></i>Full Name:- {rowDataDetail?.firstname} {rowDataDetail?.lastname} </Typography>
              <Typography variant="body1"><i className="uil uil-phone me-1"></i>User Email:- {rowDataDetail?.email}</Typography>

            </Div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ContentHeader
              avatar={
                <Badge
                  sx={{
                    width: 100, mr:5
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}


                  sx={{
                    // mr: 2,
                    '& .MuiBadge-badge': {
                      height: 35,
                      width: 35,
                      minWidth: 16,
                      overflow: 'hidden',
                      border: 1,
                      borderColor: 'common.white',
                      color: 'common.white',
                      bgcolor: 'primary.main',
                      cursor: 'pointer',
                      right: 12,
                      bottom: 12,
                    }
                  }}
                >
                  <Avatar sx={{ width: 100, height: 100 }} alt={rowDataDetail?.firstname}  src={`${pictureLink}${rowDataDetail?.picture}`}/>
                </Badge>
              }
              

              sx={{
                position: 'relative',
                zIndex: 1,

                '& .MuiCardHeader-action': {
                  alignSelf: 'center'
                }
              }}
            />
          </Grid>
        </Grid>

        <hr style={{ margin: '2rem 0' }} />

        <Div >
          <Typography variant="h5" sx={{ fontSize: 15 }}>License related: </Typography>
            <Table style={{ border: '1px solid white'}}>
                <TableRow style={{ border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Pilot License Type: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.pilotlicensetype}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Pilot License Category</TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.pilotlicensecategory}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Pilot License number: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.pilotlicensenumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Pilot License Expiry Date: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.pilotlicenseexpirydate}</TableCell>
                </TableRow>
            </Table>
        </Div>
        <hr style={{ margin: '2rem 0' }} />

        <Div >
          <Typography variant="h5" sx={{ fontSize: 15 }}>Insurance related: </Typography>
            <Table style={{ border: '1px solid white'}}>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Policy Number: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.Insurancepolicynumber}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Expiry Date:</TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.Insuranceexpirationdate}</TableCell>
                </TableRow>
            </Table>
        </Div>
        <hr style={{ margin: '2rem 0' }} />

        <Div >
          <Typography variant="h5" sx={{ fontSize: 15 }}>My equipment: </Typography>
            <Table style={{ border: '1px solid white'}}>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Wing Brand: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.wingbrand}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Wing Type: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.wingtype}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Wing Serial Number: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.wingserialnumber}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Wing Registration Number: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.wingregistrationnumber}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Wing Colors: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.wingcolors}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Paramoter Brand: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.paramotorbrand}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Moter Type: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.motortype}</TableCell>
                </TableRow>
                <TableRow style={{ width: '200px',  border: '1px solid white'}}>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>Serial Number: </TableCell>
                  <TableCell style={{ width: '200px',  border: '1px solid white'}}>{rowDataDetail?.serialnumber}</TableCell>
                </TableRow>
            </Table>
        </Div>
      </CardContent>
    </Card>
  );
};

export default Userdetail;
