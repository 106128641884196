import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { ApprovedPayoutApi, GetPayoutDetailsUpdateReport  } from 'backendServices/ApiCalls';
import { Grid } from "@mui/material";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import DeleteIcon from '@mui/icons-material/Delete';


const Approvedpayout = () => {
    const [approvedPayout, setApprovedPayout] = useState([]);

    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    const GetApprovedPayout = () => {

      ApprovedPayoutApi((response) => {
            console.log('reponse', response)
            const pendingData = response?.data?.data.filter(data => data.status !== "Pending" && data.status !== "Rejected");
            setApprovedPayout(pendingData);
            if (response?.data?.data?.status === "success") {
                console.log("response get Successfully");
            }
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    useEffect(() => {
      GetApprovedPayout();
    }, [])

    const rowsWithIndex = approvedPayout.map((row, index) => ({ ...row, id: index + 1 }));

    const columns = [
      {
        field: "index",
        headerName: "#",  
        width: 80,
        valueGetter: (params) => params.row.id,
    },
        {
            field: "username",
            headerName: "User Name",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "firstname",
            headerName: "First Name",
            width: 200,
            editable: true,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "lastname",
            headerName: "Last Name",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
       
        {
            field: "email",
            headerName: "Email",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? `€${params.value}` : "N/A";
          },
        },
        {
            field: "final_amount",
            headerName: "Final Amount",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? `€${params.value}` : "N/A";
          },
        },
        {
          field: "payoutmethod",
          headerName: "Payout Method",
          width: 200,
          editable: true,
          groupable: false,
          valueGetter: (params) => {
            const { value } = params;
            return value ? value : "N/A";
        },
          
      },
      {
        field: "payout_fee",
        headerName: "Payout Fee",
        width: 200,
        editable: true,
        groupable: false,
        valueGetter: (params) => {
          const { value } = params;
          return value ? value : "N/A";
      },
        valueGetter: (params) => {
          const payoutMethod = params.row.payoutmethod;
          const payoutFee = params.row.payout_fee;
    
          if (payoutMethod === "Bank") {
            return `€${payoutFee}`;
          } else {
            return `${payoutFee}%`;
          }
        },
        
    },
        {
            field: "bank_account_title",
            headerName: "Bank Account Title",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "bank_account_bic",
            headerName: "Bank Account BIC",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "bank_account_country",
            headerName: "Bank Account Country",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
            field: "bank_account_iban",
            headerName: "Bank Account IBAN",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
              const { value } = params;
              return value ? value : "N/A";
          },
        },
        {
          field: "wallet_address",
          headerName: "Wallet Address",
          width: 200,
          editable: true,
          groupable: false,
          valueGetter: (params) => {
            const { value } = params;
            return value ? value : "N/A";
        },
      },

    ]



    const gridDesign = {
        '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
                fontSize: '13px !important',
                color: '#fff',
            },
            '& .MuiBadge-badge': {
                backgroundColor: '#074682',
            },
            '& .MuiInput-root': {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: 'hidden',
            },

        }
    }

    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={12} xs={12}>
                <JumboDemoCard
                    title={'Payout Detail Approved'}
                    wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
                >
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }
                    {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

                    <Box sx={{ height: 500, width: 1 }}>
                        <DataGrid
                            initialState={{
                                pagination: { paginationModel: { pageSize: 6 } },
                            }}
                            rows={rowsWithIndex}

                            getRowId={(row) => row.id}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            sx={gridDesign}
                            pageSizeOptions={[6, 12, 18, 24, 30]}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Box>
                    {/* )} */}
                </JumboDemoCard>
            </Grid></Grid>
    )
}

export default Approvedpayout