import React, { useState, useEffect } from 'react';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {Card, CardContent, ListItem, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { postRequest } from 'backendServices/ApiCalls';
import { useNavigate } from 'react-router-dom';

const CurrentPlan = () => {
    const {t} = useTranslation();
    const [buyStorage, setBuyStorage] = useState([]);
    const [activeStorage, setActiveStorage] = useState([]);
    const [expireStorage, setExpireStorage] = useState([]);
    const [upgradeStorage, setUpgradeStorage] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    const Gettotalbuystorage = () => {
        postRequest(
            '/gettotalbuystorage',
            "",
            (response) => {
                setBuyStorage(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }
    
    const Gettotalactivestorage = () => {
        postRequest(
            '/gettotalactivestorage',
            "",
            (response) => {
                setActiveStorage(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }


    const Gettotalexpirestorage = () => {
        postRequest(
            '/gettotalexpirestorage',
            "",
            (response) => {
                setExpireStorage(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }



    const Gettotalupgradestorage = () => {
        postRequest(
            '/gettotalupgradestorage',
            "",
            (response) => {
                setUpgradeStorage(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }

    useEffect(() => {
        Gettotalbuystorage();
        Gettotalactivestorage();
        Gettotalexpirestorage();
        Gettotalupgradestorage();
    }, [])
    

    if(loading) {
        return (
            <Button>Loading ...</Button>
        )
    }
    return (
        <Card>
            <CardHeader title={t('widgets.title.currentPlan')}/>
            <Divider/>
            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    <Grid item xs={7}>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            mb={1}
                        >
                            <Typography component={"span"} variant={"h1"}>{buyStorage?.data}</Typography>/Total Storage
                        </Typography>
                        <Typography
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={2}
                        >
                            Storage Detail
                        </Typography>
                        <List>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {activeStorage?.data} Active Storage</ListItem>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {expireStorage?.data} Expire Storage</ListItem>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {upgradeStorage?.data} Renew Storage</ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={5}>
                        <Div sx={{textAlign: 'center'}}>
                            <img src={getAssetPath(`${ASSET_IMAGES}/icons/storage.png`, '82x82')}  alt={''} width={"82"}/>
                            <Typography variant={"h6"} color={"error"} my={1.5} sx={{pt:2}}></Typography>
                            <Button variant={"contained"} onClick={() => navigate('/storage-report')}>View Reports</Button>
                        </Div>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default CurrentPlan;
