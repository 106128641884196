import React, { useEffect, useState } from 'react';
import {Grid} from "@mui/material";
import PortfolioBalance from 'app/shared/metrics/PortfolioBalance/PortfolioBalance';
import EarningExpenses from 'app/shared/metrics/EarningExpenses/EarningExpenses';
import MarketingCampaign from 'app/shared/widgets/MarketingCampaign/MarketingCampaign';
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Referralusers from 'app/shared/widgets/ReferralUsers/ReferralUsers';
import { dashboard,roidata, referralusers, dashboardtransactions, postRequest } from 'backendServices/ApiCalls';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Group, ShoppingCart, VerifiedUser } from '@mui/icons-material';
import CurrentPlan from 'app/shared/widgets/CurrentPlan';
import MembershipPlan from 'app/shared/widgets/MembershipPlan';

const Crypto = () => {
    const [totalUser, setTotalUsers] = useState([]);
    const [activetotalUser, setTotalActiveUsers] = useState([]);
    const [inActivetotalUser, setTotalInActiveUsers] = useState([]);
    const [userroidata,setUserRoiData]=useState([])
    const [dashboarddata,setDashboardData]=useState([])
    const [lasttransactionsdata,setLastTransactionsData]=useState([])
    const [picturelink,setPictureLink]=useState([])
    const [referralusersdata,setReferralUsersData]=useState([])
    
    const {loginUserData} = useContext(CustomProvider);
    let userData = loginUserData


    const DashboardData =()=>{
        dashboard((response) => {
            setDashboardData(response?.data?.data)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const RoiData =()=>{
        roidata((response) => {
            setUserRoiData(response?.data?.data)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    const TransactionData =()=>{
        dashboardtransactions((response) => {
            setLastTransactionsData(response?.data?.data?.entries)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    const ReferralUsers =()=>{
        referralusers((response) => {
            setReferralUsersData(response?.data?.data?.entries)
            setPictureLink(response?.data?.data?.picturelink)
        }, (error) => {
            console.log(error.response.data);
        })
    } 
   

    const Gettotalusers = () => {
        postRequest(
            '/gettotalusers',
            "",
            (response) => {
                setTotalUsers(response?.data);
                // setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }
    const Gettotalactiveusers = () => {
        postRequest(
            '/gettotalactiveusers',
            "",
            (response) => {
                setTotalActiveUsers(response?.data);
                // setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }
    const Gettotalinactiveusers = () => {
        postRequest(
            '/gettotalinactiveusers',
            "",
            (response) => {
                setTotalInActiveUsers(response?.data);
                // setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }
    
    

    useEffect(()=>{
        Gettotalusers();
        Gettotalactiveusers();
        Gettotalinactiveusers();
        RoiData();
        ReferralUsers();
        TransactionData();
        DashboardData();
    },[])
    // const totalUsers = +(dashboarddata?.inactiveusers || 0) + +(dashboarddata?.activeusers || 0);




    if(userData.firstname === "")
    {

        return <div>loading</div>
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={activetotalUser?.data || 0} title='activeusers' color="success.main"  icon={<VerifiedUser fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={inActivetotalUser?.data || 0} title='inactiveusers' color="warning.main"  icon={<GroupAddIcon fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={totalUser?.data || 0} title='tusers' color="info.main"  icon={<Group fontSize='large'/>}  vertical={true}/>
            </Grid> 
            
            <Grid item xs={12} md={6}>
                <MembershipPlan/>
            </Grid>
            <Grid item xs={12} md={6}>
                <CurrentPlan/>
            </Grid>
        </Grid>
    );
};

export default Crypto;
