import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Grid } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { List } from "@mui/material";
import Div from "@jumbo/shared/Div";
import EditIcon from "@mui/icons-material/Edit";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import Addmembership from "../add-membership/Addmembership";
import { CKEditor } from "ckeditor4-react";
import ClearIcon from "@mui/icons-material/Clear";

const Membershiphistory = () => {
  const [membership, setMembership] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [userMemberOpen, setUserMemberOpen] = useState(false);
  const [closeOnOutsideClick, setCloseOnOutsideClick] = React.useState(false);
  const [ckeditorContent, setCkeditorContent] = useState("");
  console.log("rowData", rowData);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleCkeditorChange = (event) => {
    const updatedContent = event.editor.getData();
    if (updatedContent !== ckeditorContent) {
      setCkeditorContent(updatedContent);
    }
  };

  const validationSchema = yup.object({
    price: yup.number().required("Price is required"),
  });

  const Swal = useSwalWrapper();
  const sweetAlerts = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        handleDelete(params?.row?.id);
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };

  const GetmembershipData = () => {
    postRequest(
      "/getmembership",
      "",
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        console.log("response", response);
        setCkeditorContent(response?.data?.data?.detail);
        setMembership(processedData);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetmembershipData();
  }, []);

  const textContent = rowData?.detail || "";

  const handleOpen = (id) => {
    const rowToEdit = membership.find((row) => row.id === id);
    setRowData(rowToEdit);
    setCkeditorContent(rowToEdit.detail);
    setOpenUpdate(true);
  };
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "detail",
      headerName: "Description",
      width: 450,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.row.detail
              .replace(/\\n/g, "")
              .replace(/\\/g, "")
              .trim(),
          }}
        ></div>
      ),
    },
    {
      field: "price",
      headerName: "Amount",
      width: 100,
      editable: true,
      groupable: false,
    },
    {
      field: "time",
      headerName: "Duration",
      width: 100,
      editable: true,
      groupable: false,
    },

    {
      field: "update",
      headerName: "Update",
      width: 80,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: "pointer", color: "white", fontSize: "20px" }}
          onClick={() => handleOpen(params.row.id)}
        />
      ),
    },
  ];
  console.log("ckeditorContent", ckeditorContent);
  const handleUpdate = (data, setSubmitting, resetForm) => {
    let params = {
      id: rowData?.id,
      detail: ckeditorContent,
      price: data.price,
    };
    console.log("params", params);
    postRequest(
      "/updatemembershipdata",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          setOpenUpdate(false);
          GetmembershipData();
        } else {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setSubmitting(false);
      }
    );
  };

  const handleDelete = (id) => {
    let params = { id: id };
    postRequest(
      "/deletemembership",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          setUserMemberOpen(false);
        } else {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
    setMembership((data) => data.filter((row) => row.id !== id));
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  const handleModalClick = (event) => {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      // Check if the click occurred outside the modal
      handleCloseModal();
    }
  };
  const handleCloseModal = () => {
    setOpenUpdate(false);
    setCloseOnOutsideClick(false); // Reset the state
    setUserMemberOpen(false);
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage Membership"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <Box sx={{ height: 500, width: 1 }}>
            <Div>
              <Modal
                open={userMemberOpen}
                onClick={handleModalClick}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Addmembership
                  setUserMemberOpen={setUserMemberOpen}
                  GetallUsers={membership}
                />
              </Modal>
            </Div>

            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={membership}
              // getRowId={(row) => row?.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          <Modal
            open={openUpdate}
            onClick={handleModalClick}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style} style={{ minWidth: "600px" }}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update Membership
              </Typography>
              <ClearIcon
                onClick={() => setOpenUpdate(false)}
                style={{
                  cursor: "pointer",
                  color: "white",
                  position: "absolute",
                  top: 3,
                  right: 3,
                  zIndex: 1000,
                }}
                sx={{ float: "right" }}
              />

              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                <Formik
                  validateOnChange={true}
                  enableReinitialize
                  initialValues={{
                    editor: rowData?.detail || "",
                    price: rowData?.price || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleUpdate(data, setSubmitting, resetForm);
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form
                      style={{ textAlign: "left", minWidth: "350px" }}
                      noValidate
                      autoComplete="off"
                    >
                      {/* <Grid item sm={12} xs={12}>
                                                <Div sx={{ mt: 1, mb: 3, }}>
                                                    <JumboTextField
                                                        fullWidth
                                                        name="title"
                                                        label="Title"
                                                        type="text"
                                                    />
                                                </Div>
                                            </Grid> */}
                      <Grid item sm={12} xs={12}>
                        <Div sx={{ mt: 1, mb: 3 }}>
                          <CKEditor
                            id="editor"
                            name="editor"
                            initData={textContent}
                            config={{
                              contentsCss: [
                                `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                                `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                              ],
                            }}
                            onChange={handleCkeditorChange}
                            // value={eventData?.data[0]?.detail}
                          />
                        </Div>
                      </Grid>
                      <Grid container spacing={2} fullWidth>
                        <Grid item sm={12} xs={12}>
                          <Div sx={{ mt: 1, mb: 3 }}>
                            <JumboTextField
                              fullWidth
                              name="price"
                              label="Amount"
                              type="number"
                            />
                          </Div>
                        </Grid>

                        {/* <Grid item sm={6} xs={12}>
                                                    <Div sx={{ mt: 1, mb: 3, }}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="time"
                                                            label="Time"
                                                            type="text"
                                                        />
                                                    </Div>
                                                </Grid> */}
                      </Grid>
                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Membershiphistory;
