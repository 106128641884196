import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  deleteuserApi,
  getuserslistApi,
  postRequest,
  updateuserdataApi,
} from "backendServices/ApiCalls";
import { Grid, MenuItem, Select } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, List, DialogContent } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Dialog } from "@mui/material";
import Adduser from "../add-users/Adduser";
import EditIcon from "@mui/icons-material/Edit";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import Userdetail from "../users-detail/Userdetail";
import { format, parseISO } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';


const Allusers = () => {
  const [managealluser, setManageAllUsers] = useState([]);
  const [usersProfileData, setUsersProfileData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [rowDataDetail, setRowDataDetail] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [closeOnOutsideClick, setCloseOnOutsideClick] = React.useState(false);
  const [formattedPilotLicenseExpiryDate1, setFormattedPilotLicenseExpiryDate] = useState('');
    const [formattedInsuranceExpirationDate1, setFormattedInsuranceExpirationDate] = useState('');
  const [pictureLink, setPictureLink] = useState();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const validationSchema = yup.object({
    username: yup.string().required("User Name is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required"),
    emergency_contact_name: yup
        .string('Emergency contact name')
        .required('Emergency contact name is required'),
    emergency_contact_relationship: yup
        .string('emergency contact relationship')
        .required('Emergency  Contact Relationship is required'),
    emergency_contact_mobile_number: yup
        .number('emergency_contact_mobile_number')
        .required('Emergency contact mobile number is required'),
    pilot_license_type: yup
        .string('Pilot license type')
        .required('Pilot license type is required'),
    pilot_license_category: yup
        .string('pilot license  category')
        .required('Pilot license Category is required'),
    pilot_license_expiry_date: yup
        .string('pilot_license_expiry_date')
        .required('Pilot license expry date is required'),

    pilot_license_number: yup
        .number('pilot license number')
        .required('Pilot license Number is required'),
    policy_number: yup
        .number('Policy number')
        .required('Policy Number is required'),
    wing_brand: yup
        .string('wing brand')
        .required('wing brand is required'),
    wing_type: yup
        .string('wing type')
        .required('wing type is required'),
    wing_serial_number: yup
        .number('wing serial number')
        .required('wing serial number is required'),
    wing_registration_number: yup
        .number('wing registration number')
        .required('wing registeration number is required'),
    wing_colors: yup
        .string('wing color')
        .required('wing colorr is required'),
    paramotor_brand: yup
        .string('paramotor brand')
        .required('paramotor brand is required'),
    motor_type: yup
        .string('motor type')
        .required('motor type is required'),
    moter_serial_number: yup
        .number('moter serial number')
        .required('moter serial number is required'),
  });
  const Swal = useSwalWrapper();
  const sweetAlerts = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        handleDelete(params?.row?.id);
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };
  const UsersDataProfile = () => {
    postRequest(
      '/getbloodgroup',
      "",
      (response) => {
        if (response?.data?.status === 'success') {
          setUsersProfileData(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const GetallUsers = () => {
    getuserslistApi(
      (response) => {
        const processedData = response?.data?.userdata.map((row, index) => ({
          ...row,
          index: index + 1,
          // id: row?.userid
        }));
        setPictureLink(response?.data?.picturelink)
        setManageAllUsers(processedData);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    if (rowData?.pilotlicenseexpirydate) {
      setFormattedPilotLicenseExpiryDate(
        format(parseISO(rowData.pilotlicenseexpirydate), 'yyyy-MM-dd')
      );
    }

    if (rowData?.Insuranceexpirationdate) {
      setFormattedInsuranceExpirationDate(
        format(parseISO(rowData.Insuranceexpirationdate), 'yyyy-MM-dd')
      );
    }


    GetallUsers();
    UsersDataProfile();
  }, []);

  const handleOpen = (id) => {
    const rowToEdit = managealluser.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpenUpdate(true);
  };
  const handleOpenUser = (id) => {
    const rowToEdit = managealluser.find((row) => row.id === id);
    setRowDataDetail(rowToEdit);
    setUserDetailOpen(true);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      editable: true,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: true,
      groupable: false,
    },
    
    {
      field: "date",
      headerName: "Date(dd-mm-yyyy)",
      width: 200,
      editable: true,
      groupable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString("en-GB"); // "en-GB" represents the "dd-mm-yyyy" format
      },
    },
    {
      field: "detail",
      headerName: "Detail",
      width: 130,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            handleOpenUser(params.row.id);
            // setUserDetailOpen(true)
          }}
        >
          User Detail
        </Button>
      ),
    },
    {
      field: "update",
      headerName: "Update",
      width: 80,
      renderCell: (params) => (
        <EditIcon
          style={{ cursor: "pointer", color: "white" }}
          onClick={() => handleOpen(params.row.id)}
        />
      ),
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 80,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => sweetAlerts(params)}
          style={{ cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];
  const handleUpdate = (data, setSubmitting) => {
    const param = {
      id: rowData.id,
      username: data?.username,
      firstname: data?.firstname,
      lastname: data?.lastname,
      email: data?.email,
      bloodgroup: data?.blood_group,
      emergencycontactname: data?.emergency_contact_name,
      emergencycontactrelationship: data?.emergency_contact_relationship,
      emergencycontactmobile: data?.emergency_contact_mobile_number,
      pilotlicensetype: data?.pilot_license_type,
      pilotlicensecategory: data?.pilot_license_category,
      pilotlicensenumber: data?.pilot_license_number,
      pilotlicenseexpirydate: data?.pilot_license_expiry_date,
      Insurancepolicynumber: data?.policy_number,
      Insuranceexpirationdate: data?.expiration_date,
      wingbrand: data?.wing_brand,
      wingtype: data?.wing_type,
      wingserialnumber: data?.wing_serial_number,
      wingregistrationnumber: data?.wing_registration_number,
      wingcolors: data?.wing_colors,
      paramotorbrand: data?.paramotor_brand,
      motortype: data?.motor_type,
      serialnumber: data?.moter_serial_number,

    };

    const newData = { ...param, ...data };
    updateuserdataApi(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
          GetallUsers();
          setOpenUpdate(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  const handleLatestData = () => {
    GetallUsers();
}

  const handleDelete = (id) => {
    let params = { id: id };
    deleteuserApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
    setManageAllUsers((data) => data.filter((row) => row.id !== id));
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  const handleModalClick = (event) => {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      // Check if the click occurred outside the modal
      handleCloseModal();
    }
  };
  const handleCloseModal = () => {
    setOpenUpdate(false);
    setCloseOnOutsideClick(false); // Reset the state
    setUserDetailOpen(false);
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage All Users"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <Div
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-40px",
                pb: 3,
              }}
            >
              <Button variant="contained" onClick={() => setOpen(true)}>
                Add User
              </Button>
            </Div>
            <Div>
              <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                  <Adduser setOpen={setOpen} handleLatestData={handleLatestData}/>
                </DialogContent>
              </Dialog>
            </Div>
            <Div>
              <Modal
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                open={userDetailOpen}
                onClick={handleModalClick}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Userdetail setUserDetailOpen={setUserDetailOpen} rowDataDetail={rowDataDetail} pictureLink={pictureLink}/>
              </Modal>
            </Div>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={managealluser}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          <Modal
            open={openUpdate}
            onClick={handleModalClick}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style} style={{ minWidth: "800px", maxHeight: "80vh", overflowY: "scroll" }}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update User
              </Typography>
              <ClearIcon onClick={() => setOpenUpdate(false)} style={{cursor: "pointer", color: "white", position: "absolute", top: 3, right: 3,  zIndex: 1000}} sx={{ float: "right" }} />

              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    username: rowData?.username || "",
                    firstname: rowData?.firstname || "",
                    lastname: rowData?.lastname || "",
                    email: rowData?.email || "",
                    blood_group: rowData?.bloodgroup || "",
                    emergency_contact_name: rowData?.emergencycontactname || "",
                    emergency_contact_relationship: rowData?.emergencycontactrelationship || "",
                    emergency_contact_mobile_number:rowData?.emergencycontactmobile || "",
                    pilot_license_type:rowData?.pilotlicensetype || "",
                    pilot_license_category:rowData?.pilotlicensecategory || "",
                    pilot_license_number:rowData?.pilotlicensenumber || "",
                    pilot_license_expiry_date:rowData?.pilotlicenseexpirydate || "",
                    policy_number:rowData?.Insurancepolicynumber || "",
                    expiration_date:rowData?.Insuranceexpirationdate || "",
                    wing_brand:rowData?.wingbrand || "",
                    wing_type:rowData?.usertype || "",
                    wing_serial_number:rowData?.wingserialnumber || "",
                    wing_registration_number:rowData?.wingregistrationnumber || "",
                    wing_colors:rowData?.wingcolors || "",
                    paramotor_brand:rowData?.paramotorbrand || "",
                    motor_type:rowData?.motortype || "",
                    moter_serial_number:rowData?.wingserialnumber || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleUpdate(data, setSubmitting, resetForm);
                    handleClose();
                  }}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form
                      style={{ textAlign: "left", minWidth: "450px" }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <JumboTextField
                              fullWidth
                              name="username"
                              label="User Name"
                              type="text"
                              InputProps={{
                                            readOnly:"true"
                                        }}
                                        
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <JumboTextField
                              fullWidth
                              name="firstname"
                              label="First Name"
                              type="text"
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <JumboTextField
                              fullWidth
                              name="lastname"
                              label="Last Name"
                              type="text"
                            />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>

                        <Grid item sm={6} xs={12}  sx={{ mt: 2, mb: 3 }}>
                          <JumboTextField
                            fullWidth
                            name="email"
                            label="Email"
                            type="text"
                          />
                        </Grid>
                        <Grid item sm={6} sx={{ mt: 2, mb: 3}}>
                          <Select
                            fullWidth
                            labelId="select-label"
                            name='blood_group'
                            value={values.blood_group}
                            onChange={(event) => {
                              const selectedvalue = event.target.value;
                              setFieldValue("blood_group", selectedvalue);  // Update the form field value
                            }}
                          >
                            {usersProfileData?.map((row, index) => (
                              <MenuItem key={index} value={row.bloodgroupname}>{row?.bloodgroupname}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item sm={4}>
                                    <JumboTextField
                                        fullWidth
                                        label="Emergency contact name"
                                        name='emergency_contact_name'
                                        type='text'
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <JumboTextField
                                        fullWidth
                                        label="Emergency contact relationship"
                                        name='emergency_contact_relationship'
                                        type='text'
                                    />

                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <JumboTextField
                                        fullWidth
                                        label="Emergency mobile number"
                                        name='emergency_contact_mobile_number'
                                        type='number'
                                    />
                                </Grid>
                            </Grid>

                            <h3 style={{ paddingBottom: "22px" }}>License related:</h3>
                            
                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Pilot license type"
                                        name="pilot_license_type"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="pilot_license_category"
                                        label="Pilot license category "
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Pilot license number"
                                        name="pilot_license_number"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="pilot_license_expiry_date"
                                        label="Pilot license expiry date "
                                        type="date"
                                        InputProps={{
                                            value: formattedPilotLicenseExpiryDate1,
                                            onChange: (event) => {
                                                const newDate = event.target.value;
                                                setFormattedPilotLicenseExpiryDate(newDate);
                                              },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <h3 style={{ paddingBottom: "22px" }}>Insurance related:</h3>

                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Policy number"
                                        name="policy_number"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="expiration_date"
                                        label="Expiration date "
                                        type="date"
                                        InputProps={{
                                            value: formattedInsuranceExpirationDate1,
                                            onChange: (event) => {
                                                const newDate = event.target.value;
                                                setFormattedInsuranceExpirationDate(newDate);
                                              },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <h3 style={{ paddingBottom: "22px" }}>My equipment:</h3>

                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Wing brand"
                                        name="wing_brand"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="wing_type"
                                        label="Wing type"
                                        type="text"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Wing serial number"
                                        name="wing_serial_number"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="wing_registration_number"
                                        label="Wing registration number "
                                        type="number"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Wing colors"
                                        name="wing_colors"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="paramotor_brand"
                                        label="Paramotor brand"
                                        type="text"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ mb: 2 }}>

                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        label="Motor type"
                                        name="motor_type"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <JumboTextField
                                        fullWidth
                                        name="moter_serial_number"
                                        label="Serial number"
                                        type="number"
                                    />
                                </Grid>
                            </Grid>

                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Allusers;
