import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { postRequest } from 'backendServices/ApiCalls';
import { Grid } from "@mui/material";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { Button } from "@mui/material";

const Membershipreport = () => {
  const [managealluser, setManageAllUsers] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: ""
  })


  const GetallUsers = () => {
    postRequest(
      '/getbuymembershipdata',
      "",
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
          // id: row?.userid
        }));
        setManageAllUsers(processedData);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  }

  useEffect(() => {
    GetallUsers();
  }, [])


  const columns = [

    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      editable: true,

    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: true,

    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: true,

    },
    {
      field: "duration",
      headerName: "Duration",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "price",
      headerName: "Amount",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "expirydate",
      headerName: "Expiry Date(dd-mm-yyyy)",
      width: 200,
      editable: true,
      groupable: false,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString("en-GB"); // "en-GB" represents the "dd-mm-yyyy" format
      },
    },
   

    // {
    //   field: "approved",
    //   headerName: "Approved",
    //   width: 130,
    //   renderCell: (params) => (
    //     <Button variant="contained"
    //     onClick={() => {
    //         handleOpenUser(params.row.id);
    //         // setUserDetailOpen(true)
    //     }}
    //     >
    //       Approved
    //     </Button>
    //   ),
    // },
    // {
    //   field: "rejected",
    //   headerName: "Rejected",
    //   width: 130,
    //   renderCell: (params) => (
    //     <Button variant="contained"
    //     // onClick={() => {
    //     //     handleOpenUser(params.row.id);
    //     //     // setUserDetailOpen(true)
    //     // }}
    //     >
    //       Rejected
    //     </Button>
    //   ),
    // },
  ]


  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }

  return (
    <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={'Membership Report'}
          wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
        >
          {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={managealluser}

              // getRowId={(row) => row?.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}

        </JumboDemoCard>
      </Grid></Grid>
  )
}

export default Membershipreport