import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, List, MenuItem, Select } from "@mui/material";
import { useState } from 'react';
import { postRequest } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import { CKEditor } from "ckeditor4-react";


const validationSchema = yup.object({
    title: yup
        .string()
        .required('Title is required'),
    time: yup
        .string()
        .required('Time is required'),
    price: yup
        .string()
        .required('Price is required'),
});


const Addmembership = ({ setUserMemberOpen, handleLatestData,GetallUsers }) => {
    const [eventData, setEventData] = useState(null);
    const [ckeditorContent, setCkeditorContent] = useState("");
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const isMobileScreen = window.innerWidth <= 767;
    const handleCkeditorChange = (event) => {
        setCkeditorContent(event.editor.getData());
    };
    const handleSubmit = (data, setSubmitting, resetForm) => {
        let params = {
            title: data.title,
            detail: ckeditorContent,
            price: data.price,
            time:data.time,
        }
        postRequest(
            "/addmembership",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setUserMemberOpen(false)
                    GetallUsers();
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
    }

    const textContent = ''
        ;

    return (
        <Grid container fullWidth xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={6} xs={12}>
                <JumboCardQuick title={"Add Membership"} noWrapper>
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize
                            initialValues={{
                                title: '',
                                editor: "",
                                price: '',
                                time:'',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form style={{ textAlign: 'left', width: "100%",
                                 maxHeight: isMobileScreen ? '60vh' : 'none', 
                                overflowY: isMobileScreen ? 'auto' : 'visible', 
                                }} 
                                noValidate autoComplete='off'>

                                    <Div sx={{ mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="title"
                                            label="Title"
                                            type="text"
                                        />
                                    </Div>

                                    <Div sx={{ mb: 2, pl: 2, pr: 2 }}>
                                        <CKEditor
                                            id="editor"
                                            name="editor"
                                            initData={textContent}
                                            config={{
                                                contentsCss: [`${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                                                `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`],
                                            }}
                                            onChange={handleCkeditorChange}
                                        // value={eventData?.data[0]?.detail}
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <Select
                                            fullWidth
                                            // defaultValue="solo"
                                            labelId="select-label"
                                            name="time"
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setFieldValue("time", selectedValue);
                                            }}
                                        >
                                            <MenuItem value="Monthly">Monthly</MenuItem>
                                            <MenuItem value="6 Month">6 Month</MenuItem>
                                            <MenuItem value="Yearly">Yearly</MenuItem>
                                        </Select>
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="price"
                                            label="Price"
                                            type="number"
                                        />
                                    </Div>



                                    <Div sx={{mb:5, mt: 1, pl: 2, pr: 2, }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Addmembership;
