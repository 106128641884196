import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Grid, ListItem, ListItemText, TextField } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Button } from "@mui/material";
import Div from "@jumbo/shared/Div";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Documentsdetail from "../user-documents-detail/Documentsdetail";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import ClearIcon from "@mui/icons-material/Clear";

const Userdocuments = () => {
  const [userDocuments, setUserDocuments] = useState([]);
  const [rowData, setRowData] = useState();
  const [rowDataDetail, setRowDataDetail] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [userDetailOpen, setUserDetailOpen] = useState(false);
  const [closeOnOutsideClick, setCloseOnOutsideClick] = React.useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  

  const Getusersdocuments = () => {
    postRequest(
      "/getusersdocuments",
      "",
      (response) => {
        const processedData = response?.data?.userdata?.map((row, index) => ({
          ...row,
          index: index + 1,
          // id: row?.userid
        }));
        setImageUrl(response?.data.picturelink);
        setUserDocuments(processedData);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    Getusersdocuments();
  }, []);

  const updatedData = () => {
    Getusersdocuments();
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      editable: true,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: true,
      groupable: false,
    },
    {
      field: "detail",
      headerName: "Detail",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenUser(params.row.id);
          }}
        >
          User Documents
        </Button>
      ),
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleOpen = (id) => {
    const rowToEdit = userDocuments?.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpenUpdate(true);
  };
  const handleOpenUser = (id) => {
    const rowToEdit = userDocuments?.find((row) => row.id === id);
    setRowDataDetail(rowToEdit);
    setUserDetailOpen(true);
  };

  const handleModalClick = (event) => {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      // Check if the click occurred outside the modal
      handleCloseModal();
    }
  };
  const handleCloseModal = () => {
    setOpenUpdate(false);
    setCloseOnOutsideClick(false); // Reset the state
    setUserDetailOpen(false);
  };

  const labels = {
    pilotlicensecard: "Pilot License Card",
    insurancepolicy: "Insurance Policy",
    insurancecertificate: "Insurance Certificate",
    mypicture: "My Picture",
    mysecurityclearance: "My Security Clearance",
    mypassportcopy: "My Passport Copy",
    myeidcopy: "My EID Copy",
    liabilitywaiver: "Liability Waiver",
    medicalfitnessdeclaration: "Medical Fitness Declaration",
    rulesandregulationsagreement: "Rules and Regulations Agreement",
  };

  const [documentData, setDocumentData] = useState({
    pilotlicensecard: null,
    insurancepolicy: null,
    insurancecertificate: null,
    mypicture: null,
    mysecurityclearance: null,
    mypassportcopy: null,
    myeidcopy: null,
    liabilitywaiver: null,
    medicalfitnessdeclaration: null,
    rulesandregulationsagreement: null,
  });

  const updateDocumentData = (row) => {
    if (row) {
      const updatedData = {
        pilotlicensecard: row.pilotlicensecard || null,
        insurancepolicy: row.insurancepolicy || null,
        insurancecertificate: row.insurancecertificate || null,
        mypicture: row.mypicture || null,
        mysecurityclearance: row.mysecurityclearance || null,
        mypassportcopy: row.mypassportcopy || null,
        myeidcopy: row.myeidcopy || null,
        liabilitywaiver: row.liabilitywaiver || null,
        medicalfitnessdeclaration: row.medicalfitnessdeclaration || null,
        rulesandregulationsagreement: row.rulesandregulationsagreement || null,
      };
      setDocumentData(updatedData);
    }
  };

  const handleFileChange = (event, name) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && /^image\/(jpeg|jpg|png)$/.test(selectedFile.type)) {
      setDocumentData({ ...documentData, [name]: selectedFile });
    } else {
      alert("Please select a valid image file (JPEG or PNG format).");
      event.target.value = null;
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();

    Object.keys(documentData).forEach((key) => {
      if (documentData[key]) {
        formData.append(key, documentData[key]);
      }
    });
    postRequest(
      "/updatedocument",
      formData,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
          setOpenUpdate(false);
        } else {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: response?.data?.status,
          });
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage User Documents"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }}>
            <Div>
              <Modal
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                open={userDetailOpen}
                onClick={handleModalClick}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Documentsdetail
                  rowDataDetail={rowDataDetail}
                  imageUrl={imageUrl}
                  setUserDetailOpen={setUserDetailOpen}
                  updatedData={updatedData}
                />
              </Modal>
            </Div>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={userDocuments}
              // getRowId={(row) => row?.id}
              columns={columns}
              onRowClick={(params) => {
                // Call the updateDocumentData function when a row is clicked
                updateDocumentData(params.row);
                handleOpen(params.row.id);
              }}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          <Modal
            open={openUpdate}
            onClick={handleModalClick}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth
          >
            <Grid
              container
              sm={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
              pt={5}
              sx={{ m: "auto" }}
            >
              <Grid item sm={6} sx={{ maxHeight: "80vh", overflowY: "scroll" }}>
                {alertData.show && (
                  <SweetAlert
                    alertData={alertData}
                    setAlertData={setalertData}
                  />
                )}
                <JumboCardQuick title={"Update Document"} noWrapper>
                  {/* Render the file input fields with two columns */}
                  <Grid item sm={12}>
                    <ClearIcon
                      onClick={() => setOpenUpdate(false)}
                      style={{
                        cursor: "pointer",
                        color: "white",
                        position: "absolute",
                        top: 3,
                        right: 3,
                        zIndex: 1000,
                      }}
                      sx={{ float: "right" }}
                    />
                  </Grid>
                  <Grid container spacing={2} sx={{ mb: -5 }}>
                    {Object.keys(documentData).map((key, index) => (
                      <Grid item sm={6} key={key}>
                        <ListItem>
                          <ListItemText
                            primary={labels[key]}
                            secondary={
                              <TextField
                                type="file"
                                margin="normal"
                                onChange={(event) =>
                                  handleFileChange(event, key)
                                }
                                InputProps={{
                                  endAdornment: (
                                    <CameraAltIcon
                                      fontSize={"small"}
                                      color={"success"}
                                    />
                                  ),
                                }}
                              />
                            }
                          />
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      marginTop: 3,
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </JumboCardQuick>
              </Grid>
            </Grid>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Userdocuments;
