import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {postRequest} from 'backendServices/ApiCalls';
import { Grid } from "@mui/material";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, List, DialogContent } from "@mui/material";
import Div from '@jumbo/shared/Div';
import { Dialog } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import Addstorage from '../add-storage/Addstorage';
import ClearIcon from '@mui/icons-material/Clear';




const Stotragehistory = () => {
    const [getStorage, setGetStorage] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [closeOnOutsideClick, setCloseOnOutsideClick] = React.useState(false);

    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const validationSchema = yup.object({
        title: yup
            .string()
            .required("Title is required"),
        monthly: yup
            .number()
            .required("Month is required"),
        six_month: yup
            .number()
            .required("Six Month is required"),
        yearly: yup
            .number()
            .required("Yearly is required"),
            

    });
    const Swal = useSwalWrapper();
    const sweetAlerts = (params) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
                handleDelete(params?.row?.id);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
            }
        });
    }

    const GetallUsers = () => {

        postRequest(
            '/getstorage',
            "",
            (response) => {
                const processedData = response?.data?.data?.map((row, index) => ({
                    ...row,
                    index: index + 1,
                    // id: row?.userid
                }));
                setGetStorage(processedData);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }

    useEffect(() => {
        GetallUsers();
    }, [])

    const handleOpen = (id) => {
        const rowToEdit = getStorage.find((row) => row.id === id);
        setRowData(rowToEdit);
        setOpenUpdate(true);
    };
    // const handleOpenUser = (id) => {
    //     const rowToEdit = getStorage.find((row) => row.id === id);
    //     setRowDataDetail(rowToEdit);
    //     setUserDetailOpen(true);
    // };

    const columns = [

        {
            field: "index",
            headerName: "#",
            width: 80,
        },
        {
            field: "storagetitle",
            headerName: "Title",
            width: 150,
            editable: true,

        },
        {
            field: "monthlyprice",
            headerName: "Monthly Amount",
            width: 150,
            editable: true,
            groupable: false,
        },
        {
            field: "sixmonthprice",
            headerName: "Six Month Amount",
            width: 150,
            editable: true,
            groupable: false,
        },
        {
            field: "yearlyprice",
            headerName: "Yearly Amount",
            width: 150,
            editable: true,
            groupable: false,
        },


        // {
        //     field: "detail",
        //     headerName: "Detail",
        //     width: 130,
        //     renderCell: (params) => (
        //         <Button variant="contained"
        //             onClick={() => {
        //                 handleOpenUser(params.row.id);
        //                 // setUserDetailOpen(true)
        //             }}
        //         >
        //             Detail User
        //         </Button>
        //     ),
        // },
        {
            field: "update",
            headerName: "Update",
            width: 80,
            renderCell: (params) => (
              <EditIcon
                style={{ cursor: "pointer", color: "white", fontSize: "20px" }}
                onClick={() => handleOpen(params.row.id)}
              />
            ),
          },
        {
            field: 'delete',
            headerName: 'Delete',
            width: 80,
            renderCell: (params) => (
                <DeleteIcon
                    onClick={() => sweetAlerts(params)}
                    style={{ cursor: 'pointer', color: "red", fontSize: "20px" }}
                />
            ),
        },

    ]
    const handleUpdate = (data, setSubmitting, resetForm) => {
       
        let params = {
            id:rowData?.id,
            storagetitle: data.title,
            monthlyprice: data.monthly,
            sixmonthprice: data.six_month,
            yearlyprice: data.yearly,
        }
        postRequest(
            "/updatestoragedata",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setUserMemberOpen(false)
                    GetallUsers();
                    setOpenUpdate(false);
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)
                // setOpenUpdate(false);
            }
        );
    };

    const handleDelete = (id) => {
        let params = { id: id }
        postRequest(
            "/deletestorage",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setUserMemberOpen(false)
                } else {
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })

                }

            },
            (error) => {
                console.log(error?.response?.data);

            }
        );
        setGetStorage((data) => data.filter((row) => row.id !== id));
    }



    const gridDesign = {
        '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
                fontSize: '13px !important',
                color: '#fff',
            },
            '& .MuiBadge-badge': {
                backgroundColor: '#074682',
            },
            '& .MuiInput-root': {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: 'hidden',
            },

        }
    }
    const handleModalClick = (event) => {
        if (closeOnOutsideClick && event.target === event.currentTarget) {
            // Check if the click occurred outside the modal
            handleCloseModal();
        }
    };
    const handleCloseModal = () => {
        setOpenUpdate(false);
        setCloseOnOutsideClick(false); // Reset the state
        // setUserDetailOpen(false);
        // setUserMemberOpen(false);
        setOpen(false);
    };


    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={12} xs={12}>
                <JumboDemoCard
                    title={'Manage Storage'}
                    wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
                >

                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }
                    {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

                    <Box sx={{ height: 500, width: 1 }}>
                        <Div sx={{ display: "flex", justifyContent: "flex-end", marginTop: "-40px", pb: 3 }}>
                            <Button variant="contained" onClick={() => setOpen(true)}>Add Storage</Button>
                        </Div>
                        <Div>
                            {/* <Modal
                                open={userMemberOpen}
                                onClick={handleModalClick}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{marginTop:5}}
                            >
                                <Addstorage setUserMemberOpen={setUserMemberOpen}  GetallUsers={GetallUsers}/>
                            </Modal> */}
                            <Dialog open={open}  onClose={() => setOpen(false)}>
                                <DialogContent>
                                    <Addstorage setOpen={setOpen} GetallUsers={GetallUsers} />
                                </DialogContent>

                            </Dialog>
                        </Div>
                        {/* <Div>
                            <Modal
                                style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto" }}
                                open={userDetailOpen}
                                onClick={handleModalClick}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Userdetail rowDataDetail={rowDataDetail} />
                            </Modal>
                        </Div> */}
                        <DataGrid
                            initialState={{
                                pagination: { paginationModel: { pageSize: 6 } },
                            }}
                            rows={getStorage}

                            // getRowId={(row) => row?.id}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            sx={gridDesign}
                            pageSizeOptions={[6, 12, 18, 24, 30]}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Box>
                    {/* )} */}
                    <Modal
                        open={openUpdate}
                        onClick={handleModalClick}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Div sx={style} style={{ minWidth: "600px" }}>
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                Update Storage
                            </Typography>
                            <ClearIcon onClick={() => setOpenUpdate(false)} style={{cursor: "pointer", color: "white", position: "absolute", top: 3, right: 3,  zIndex: 1000}} sx={{ float: "right" }} />

                            <List disablePadding sx={{ mb: 2, mt: 5 }}>
                                <Formik
                                    validateOnChange={true}
                                    initialValues={{
                                        // company_name: rowData?.companyname || "",
                                        title: rowData?.storagetitle || "",
                                        monthly: rowData?.monthlyprice || "",
                                        six_month: rowData?.sixmonthprice || "",
                                        yearly: rowData?.yearlyprice || "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(data, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        handleUpdate(data, setSubmitting, resetForm);
                                        handleClose();
                                    }}
                                >
                                    {({ isSubmitting, setFieldValue }) => (
                                        <Form
                                            style={{ textAlign: "left", minWidth: "350px" }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                                <Grid item sm={12} xs={12}>
                                                    <Div sx={{ mt: 1, mb: 3, }}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="title"
                                                            label="Title"
                                                            type="text"
                                                        />
                                                    </Div>
                                                </Grid>

                                                <Grid item sm={12} xs={12}>
                                                    <Div sx={{ mt: 1, mb: 3, }}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="monthly"
                                                            label="Amount"
                                                            type="number"
                                                        />
                                                    </Div>
                                                </Grid>

                                                <Grid item sm={12} xs={12}>
                                                    <Div sx={{ mt: 1, mb: 3, }}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="six_month"
                                                            label="Time"
                                                            type="number"
                                                        />
                                                    </Div>
                                                </Grid>
                                                <Grid item sm={12} xs={12}>
                                                    <Div sx={{ mt: 1, mb: 3, }}>
                                                        <JumboTextField
                                                            fullWidth
                                                            name="yearly"
                                                            label="Time"
                                                            type="number"
                                                        />
                                                    </Div>
                                                </Grid>


                                            <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                                <LoadingButton
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    sx={{ mb: 3 }}
                                                    loading={isSubmitting}
                                                >
                                                    Submit
                                                </LoadingButton>
                                            </Div>
                                        </Form>
                                    )}
                                </Formik>
                            </List>
                        </Div>
                    </Modal>
                </JumboDemoCard>
            </Grid></Grid>
    )
}

export default Stotragehistory