import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, List, MenuItem, Select } from "@mui/material";
import { useState } from 'react';
import { postRequest, registerApi } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';

const validationSchema = yup.object({
    storage_title: yup
        .string()
        .required('Title is required'),
    monthly: yup
        .number()
        .required('Monthly is required'),
    six_month: yup
        .number()
        .required('Six Month is required'),
    yearly: yup
        .number()
        .required('Year is required'),
});


const Addstorage = ({ setOpen, handleLatestData, GetallUsers }) => {
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
  
    const handleSubmit = (data, setSubmitting, resetForm) => {
        let params = {
            storagetitle: data.storage_title,
            monthlyprice: data.monthly,
            sixmonthprice:data.six_month,
            yearlyprice:data.yearly,
        }
        postRequest(
            "/addstorage",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    setOpen(false)
                    GetallUsers();
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpenUpdate(false);

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
    }
   

    return (
        <Grid container fullWidth sm={12} xs={12}  alignItems="center" justifyContent="center" >
            <Grid item sm={6} xs={12} style={{minWidth:"340px", padding:"5px"}}>
                <JumboCardQuick title={"Add Storage"} noWrapper>
                <ClearIcon onClick={() => setOpen(false)} style={{cursor: "pointer", color: "white", position: "absolute", top: 3, right: 3,  zIndex: 1000}} sx={{ float: "right" }} />

                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <List disablePadding>
                        <Formik
                            validateOnChange={true}
                            enableReinitialize
                            initialValues={{
                                storage_title: '',
                                monthly:"",
                                six_month:"",
                                yearly:"",

                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                console.log("formikData", data)
                                handleSubmit(data, setSubmitting, resetForm);
                            }}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form style={{ textAlign: 'left'}} noValidate autoComplete='off'>

                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="storage_title"
                                            label="Title"
                                            type="text"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="monthly"
                                            label="Month"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="six_month"
                                            label="Six Month"
                                            type="number"
                                        />
                                    </Div>
                                    <Div sx={{ mt: 1, mb: 2, pl: 2, pr: 2 }}>
                                        <JumboTextField
                                            fullWidth
                                            name="yearly"
                                            label="Yearly"
                                            type="number"
                                        />
                                    </Div>

                                    <Div sx={{ mt: 1, pl: 2, pr: 2, }}>
                                        <LoadingButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            sx={{ mb: 3 }}
                                            loading={isSubmitting}
                                        >Submit</LoadingButton>
                                    </Div>
                                </Form>
                            )}
                        </Formik>
                    </List>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default Addstorage;
