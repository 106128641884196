import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { ApproveAllPayoutApi, ApproveSinglePayoutApi, PendingPayoutApi,  } from 'backendServices/ApiCalls';
import { Button, Grid, IconButton, TableCell } from "@mui/material";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Div from '@jumbo/shared/Div/Div';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const Pendingpayout = () => {
    const [pendingPayout, setPendingPayout] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [feeData, setFeeData] = useState();
    console.log("feeData", feeData)
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })

    const PendingPayoutapidata = () => {

        PendingPayoutApi((response) => {
            console.log("reposen", response)
            const processedData = response?.data?.data?.map((row, index) => ({
                ...row,
                index: index + 1,
            }));
            setPendingPayout(processedData);
            setFeeData(response?.data?.feeData)
            if (response?.data?.data?.status === "success") {
                console.log("response get Successfully");
            }
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    useEffect(() => {
        PendingPayoutapidata();
    }, [])
    const rowsWithIndex = pendingPayout?.map((row) => ({ ...row, id: row.id }));

    const handleApproved = (id) => {
        console.log("functionid", id)
        let params = { userid: id }
        ApproveSinglePayoutApi(params, (response) => {
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error"
                })
            }
            else if (response?.data?.status === "success") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                })
                setPendingPayout((data) => data.filter((row) => row.id !== id));
            }
            else {
                setalertData({
                    show: true,
                    message: 'Something went wrong please try again later',
                    variant: "error"
                })
            }
        }, (error) => {
            console.log(error?.response?.data);
        });
    }

    const columns = [
        {
            field: "index",
            headerName: "#",
            width: 80,
            // valueGetter: (params) => params.row.index + 1,
        },
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 80,
        //     valueGetter: (params) => params.row.id,
        // },
        {
            field: "username",
            headerName: "User Name",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "firstname",
            headerName: "First Name",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "lastname",
            headerName: "Last Name",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "current_balance",
            headerName: "Amount",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? `€${params.value}` : "N/A";
            },
        },
        {
            field: "final_amount",
            headerName: "Final Amount",
            width: 200,
            editable: true,
            groupable: false,
            valueGetter: (params) => `€${params.value}`,
            valueGetter: (params) => {
                const walletAddress = params.row.wallet_address;
                const bankAccountTitle = params.row.bank_account_title;
                const currentBalance = params.row.current_balance;
            
                if (walletAddress !== null) {
                  const finalAmount = currentBalance - (currentBalance * feeData?.percentage / 100);
                  return `€${finalAmount}`;
                } else if (walletAddress === null && bankAccountTitle !== null) {
                  return `€${currentBalance - feeData?.flat_fee}`;
                }
            
                return "N/A";
              },
        },
        {
            field: "bank_account_title",
            headerName: "Bank Account Title",
            width: 200,
            editable: true,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "bank_account_country",
            headerName: "Bank Account Country",
            width: 215,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "bank_account_iban",
            headerName: "Bank Account IBAN",
            width: 250,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "wallet_address",
            headerName: "Address",
            width: 250,
            editable: true,
            groupable: false,
            valueGetter: (params) => {
                const { value } = params;
                return value ? value : "N/A";
            },
        },
        {
            field: "approved",
            headerName: "Approved",
            width: 200,
            editable: true,
            groupable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApproved(params.row.id)}
                >
                    Approved
                </Button>
            ),
        },

    ]


    const gridDesign = {
        '& .MuiDataGrid-toolbarContainer': {
            '& .MuiButton-text': {
                fontSize: '13px !important',
                color: '#fff',
            },
            '& .MuiBadge-badge': {
                backgroundColor: '#074682',
            },
            '& .MuiInput-root': {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: 'hidden',
            },

        }
    }
    const handleClickSendToAll =() => {
        ApproveAllPayoutApi( (response) => {
            if (response?.data?.status === "error") {
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "error"
                })
            }
            else if (response?.data?.status === "success") {
               
                setalertData({
                    show: true,
                    message: response?.data?.message,
                    variant: "success"
                })
                setOpen(false);
                PendingPayoutapidata();
            }
            else {
                setalertData({
                    show: true,
                    message: 'Something went wrong please try again later',
                    variant: "error"
                })
            }
        }, (error) => {
            console.log(error?.response?.data);
        });
    }
    const Swal = useSwalWrapper();
    const sweetAlerts = () => {
        Swal.fire({
            title: 'Alert!',
            text: 'approved are succesfully send to all.',
        });
    }
   
    return (
        <Grid container fullWidth sm={12} xs={12} p={2} alignItems="center" justifyContent="center">
            <Grid item sm={12} xs={12}>
                <Div sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button variant="contained" onClick={() => setOpen(true)}>
                    See To All
                </Button>

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" sx={{color: "green", display: "flex", justifyContent: "center"}}>
                        {"Alert!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to approve all payouts? 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                           <Button sx={{marginRight: "5rem"}} onClick={handleClickSendToAll}>Yes</Button>
                            <Button onClick={() => setOpen(false)} autoFocus>Cancel </Button> 
                    </DialogActions>
                </Dialog>

                {/* <Button variant="contained" endIcon={<SendIcon />} onClick={handleClickSendToAll} >
                    Send To All
                </Button> */}
                </Div>

                <JumboDemoCard
                    title={'Pending Payout'}
                    wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
                >
                    {
                        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
                    }

                    <Box sx={{ height: 500, width: 1 }}>
                        <DataGrid
                            initialState={{
                                pagination: { paginationModel: { pageSize: 6 } },
                            }}
                            rows={rowsWithIndex}

                            getRowId={(row) => row.id}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            sx={gridDesign}
                            pageSizeOptions={[6, 12, 18, 24, 30]}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Box>
                    {/* )} */}
                </JumboDemoCard>
            </Grid></Grid>
    )
}

export default Pendingpayout