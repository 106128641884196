import React, { useEffect, useState } from 'react';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {Card, CardContent, ListItem, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import { postRequest } from 'backendServices/ApiCalls';
import { Navigate, useNavigate } from 'react-router-dom';

const MembershipPlan = () => {
    const {t} = useTranslation();
    const [buyMembership, setBuyMembership] = useState([]);
    const [activeMembership, setActiveMembership] = useState([]);
    const [expireMembership, setExpireMembership] = useState([]);
    const [upgradeMembership, setUpgradeMembership] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    const Gettotalbuymembership = () => {
        postRequest(
            '/gettotalbuymembership',
            "",
            (response) => {
                setBuyMembership(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }
    
    const Gettotalactivemembership = () => {
        postRequest(
            '/gettotalactivemembership',
            "",
            (response) => {
                setActiveMembership(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }


    const Gettotalexpiremembership = () => {
        postRequest(
            '/gettotalexpiremembership',
            "",
            (response) => {
                setExpireMembership(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }



    const Gettotalupgrademembership = () => {
        postRequest(
            '/gettotalupgrademembership',
            "",
            (response) => {
                setUpgradeMembership(response?.data);
                setLoading(false);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }

    useEffect(() => {
        Gettotalbuymembership();
        Gettotalactivemembership();
        Gettotalexpiremembership();
        Gettotalupgrademembership();
    }, [])
    

    if(loading) {
        return (
            <Button>Loading ...</Button>
        )
    }
    return (
        <Card>
            <CardHeader title={t('widgets.title.membershipPlan')}/>
            <Divider/>
            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    <Grid item xs={7}>
                        <Typography
                            component={"div"}
                            variant={"body1"}
                            mb={1}
                        >
                            <Typography component={"span"} variant={"h1"}>{buyMembership?.data}</Typography>/Total Membership
                        </Typography>
                        <Typography
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={2}
                        >
                           Membership Detail
                        </Typography>
                        <List>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {activeMembership?.data} Active Membership</ListItem>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {expireMembership?.data} Expire Membership</ListItem>
                            <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/> {upgradeMembership?.data} Renew Membership</ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={5}>
                        <Div sx={{textAlign: 'center'}}>
                            <img src={getAssetPath(`${ASSET_IMAGES}/icons/membership.png`, '82x82')}  alt={''} width={"82"}/>
                            <Typography variant={"h6"} color={"error"} my={1.5} sx={{pt:2}}></Typography>
                            <Button variant={"contained"} onClick={() => navigate('/membership-report')}>View Reports</Button>
                        </Div>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default MembershipPlan;
